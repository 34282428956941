
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'
import {Grid} from 'components/Grid'
import {GridCol} from 'components/GridCol'
import {ContactForm} from 'components/ContactForm'
import {ContactMap} from 'components/ContactMap'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Grid mdxType="Grid">
      <GridCol mdxType="GridCol">
        <ContactForm mdxType="ContactForm" />
      </GridCol>
      <GridCol mdxType="GridCol">
        <ContactMap mdxType="ContactMap" />
      </GridCol>
    </Grid>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    